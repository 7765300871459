import { ActionType, createReducer } from 'typesafe-actions'

import appointmentActions from 'src/store/appointment/actions'
import {
  LoadingContext,
  LoadingContextDefault,
  LoadingState,
} from '../../../utils/types'
import { makeLoading } from '../../../utils/store'
import { extractErrorMessage } from '../../../utils/errors'

interface State {
  slotsLoading: LoadingContext
  clinicByZipLoading: LoadingContext
  cancelApptLoading: LoadingContext
  stripePublicKeyLoading: LoadingContext
  createPaymentTokenSubmitting: LoadingContext
  scheduleApptLoading: LoadingContext
  savePaymentMethodSubmitting: LoadingContext
  payForAppointmentSubmitting: LoadingContext
  checkForPendingAppointmentLoading: LoadingContext
  productByIdLoading: { [key: string]: LoadingContext }
  fetchingPaymentMethods: LoadingContext
  fetchingCopayStatus: LoadingContext
  fetchingCopayAmount: LoadingContext
  fetchingReasonsForConsultation: LoadingContext
}

const initialState: State = {
  slotsLoading: LoadingContextDefault,
  clinicByZipLoading: LoadingContextDefault,
  cancelApptLoading: LoadingContextDefault,
  stripePublicKeyLoading: LoadingContextDefault,
  createPaymentTokenSubmitting: LoadingContextDefault,
  scheduleApptLoading: LoadingContextDefault,
  savePaymentMethodSubmitting: LoadingContextDefault,
  payForAppointmentSubmitting: LoadingContextDefault,
  checkForPendingAppointmentLoading: LoadingContextDefault,
  productByIdLoading: {},
  fetchingPaymentMethods: LoadingContextDefault,
  fetchingCopayStatus: LoadingContextDefault,
  fetchingCopayAmount: LoadingContextDefault,
  fetchingReasonsForConsultation: LoadingContextDefault,
}

export default createReducer<State, ActionType<typeof appointmentActions>>(
  initialState
)
  .handleAction(
    [
      appointmentActions.getAvailableSlots.request,
      appointmentActions.getInClinicAvailableSlots.request,
    ],
    (state): State => ({
      ...state,
      slotsLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    [
      appointmentActions.getAvailableSlots.success,
      appointmentActions.getInClinicAvailableSlots.success,
    ],
    (state): State => ({
      ...state,
      slotsLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    [
      appointmentActions.getAvailableSlots.failure,
      appointmentActions.getInClinicAvailableSlots.failure,
    ],
    (state, { payload: { message } }): State => ({
      ...state,
      slotsLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    appointmentActions.getClinicByZip.request,
    (state): State => ({
      ...state,
      clinicByZipLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getClinicByZip.success,
    (state): State => ({
      ...state,
      clinicByZipLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getClinicByZip.failure,
    (state, { payload: { message } }): State => ({
      ...state,
      clinicByZipLoading: makeLoading(LoadingState.failure, message),
    })
  )
  .handleAction(
    appointmentActions.cancelAppointment.request,
    (state): State => ({
      ...state,
      cancelApptLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.cancelAppointment.success,
    (state): State => ({
      ...state,
      cancelApptLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.cancelAppointment.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      cancelApptLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.getStripePublicKey.request,
    (state): State => ({
      ...state,
      stripePublicKeyLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getStripePublicKey.success,
    (state): State => ({
      ...state,
      stripePublicKeyLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getStripePublicKey.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      stripePublicKeyLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.createPaymentToken.request,
    (state): State => ({
      ...state,
      createPaymentTokenSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.createPaymentToken.success,
    (state): State => ({
      ...state,
      createPaymentTokenSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.createPaymentToken.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      createPaymentTokenSubmitting: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
  .handleAction(
    appointmentActions.scheduleAppointment.request,
    (state): State => ({
      ...state,
      scheduleApptLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.scheduleAppointment.success,
    (state): State => ({
      ...state,
      scheduleApptLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.scheduleAppointment.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      scheduleApptLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.savePaymentMethod.request,
    (state): State => ({
      ...state,
      savePaymentMethodSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.savePaymentMethod.success,
    (state): State => ({
      ...state,
      savePaymentMethodSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.savePaymentMethod.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      savePaymentMethodSubmitting: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.payForAppointment.request,
    (state): State => ({
      ...state,
      payForAppointmentSubmitting: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.checkApptPaymentStatus.success,
    (state): State => ({
      ...state,
      payForAppointmentSubmitting: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    [
      appointmentActions.payForAppointment.failure,
      appointmentActions.checkApptPaymentStatus.failure,
    ],
    (state, { payload: { error } }): State => ({
      ...state,
      payForAppointmentSubmitting: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.checkForPendingAppointment.request,
    (state): State => ({
      ...state,
      checkForPendingAppointmentLoading: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.checkForPendingAppointment.success,
    (state): State => ({
      ...state,
      checkForPendingAppointmentLoading: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.checkForPendingAppointment.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      checkForPendingAppointmentLoading: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )

  .handleAction(
    appointmentActions.getProductById.request,
    (state, { payload }): State => ({
      ...state,
      productByIdLoading: {
        ...state.productByIdLoading,
        [payload]: makeLoading(LoadingState.loading),
      },
    })
  )
  .handleAction(
    appointmentActions.getProductById.success,
    (state, { payload: { productId } }): State => ({
      ...state,
      productByIdLoading: {
        ...state.productByIdLoading,
        [productId]: makeLoading(LoadingState.success),
      },
    })
  )
  .handleAction(
    appointmentActions.getProductById.failure,
    (state, { payload: { productId, error } }): State => ({
      ...state,
      productByIdLoading: {
        ...state.productByIdLoading,
        [productId]: makeLoading(
          LoadingState.failure,
          extractErrorMessage(error),
          error
        ),
      },
    })
  )
  .handleAction(
    appointmentActions.getPaymentMethods.request,
    (state): State => ({
      ...state,
      fetchingPaymentMethods: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getPaymentMethods.success,
    (state): State => ({
      ...state,
      fetchingPaymentMethods: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getPaymentMethods.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      fetchingPaymentMethods: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
  .handleAction(
    appointmentActions.getCopayStatus.request,
    (state): State => ({
      ...state,
      fetchingCopayStatus: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getCopayStatus.success,
    (state): State => ({
      ...state,
      fetchingCopayStatus: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getCopayStatus.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      fetchingCopayStatus: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
  .handleAction(
    appointmentActions.getCopayInfo.request,
    (state): State => ({
      ...state,
      fetchingCopayAmount: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getCopayInfo.success,
    (state): State => ({
      ...state,
      fetchingCopayAmount: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getCopayInfo.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      fetchingCopayAmount: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
  .handleAction(
    appointmentActions.getReasonsForConsultation.request,
    (state): State => ({
      ...state,
      fetchingReasonsForConsultation: makeLoading(LoadingState.loading),
    })
  )
  .handleAction(
    appointmentActions.getReasonsForConsultation.success,
    (state): State => ({
      ...state,
      fetchingReasonsForConsultation: makeLoading(LoadingState.success),
    })
  )
  .handleAction(
    appointmentActions.getReasonsForConsultation.failure,
    (state, { payload: { error } }): State => ({
      ...state,
      fetchingReasonsForConsultation: makeLoading(
        LoadingState.failure,
        extractErrorMessage(error),
        error
      ),
    })
  )
