import React from 'react'
import { Button, ButtonProps, CircularProgress } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'

type ThemedButtonProps = {
  loading?: boolean
} & ButtonProps

const useStyles = makeStyles(
  () => ({
    root: {
      borderRadius: '12px',
      textTransform: 'none',
      fontSize: '1rem',
      fontFamily: 'NeurialGrotesk',
      fontWeight: 700,
    },
  }),
  { name: 'ThemedButton' }
)

const ThemedButton = React.forwardRef<HTMLButtonElement, ThemedButtonProps>(
  ({ loading, disabled, children, ...props }, ref) => {
    const classes = useStyles()

    return (
      <Button
        ref={ref}
        fullWidth
        color="primary"
        variant="contained"
        classes={{ root: classes.root }}
        disabled={loading || disabled}
        {...props}
      >
        {loading ? <CircularProgress size={30} /> : children}
      </Button>
    )
  }
)

export default ThemedButton
