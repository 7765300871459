import React, { useEffect, useMemo, useCallback } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import AuthLayout from '../../components/layouts/AuthLayout'
import useLoadingFailure from '../../hooks/useLoadingFailure'
import useLoadingSuccess from '../../hooks/useLoadingSuccess'
import { RoutePath } from '../../routes'
import appointmentActions from '../../store/appointment/actions'
import {
  getSavedPaymentId,
  getScheduleApptLoading,
  getSelectedPaymentMethod,
  getIsTelemedicineClinicFlag,
  getScheduledAppointment,
  getPayForAppointmentSubmitting,
} from '../../store/appointment/selectors'
import { IndicatedPaymentMethod } from '../../store/appointment/types'
import {
  ErrorCodes,
  extractErrorCode,
  extractErrorMessage,
} from '../../utils/errors'
import { getLoadingState } from '../../utils/types'
import {
  getESignatureSubmitting,
  getStoredSignature,
} from '../../store/auth/selectors'
import authActions from '../../store/auth/actions'
import useLoadingFailureAlert from '../../hooks/useLoadingFailureAlert'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

const CreateApptLoading: React.FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const scheduleApptLoading = useSelector(getScheduleApptLoading)
  const savedPaymentId = useSelector(getSavedPaymentId)
  const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
  const isTelemedicineClinic = useSelector(getIsTelemedicineClinicFlag)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const storedSignature = useSelector(getStoredSignature)

  const scheduledAppointment = useSelector(getScheduledAppointment)

  const eSignatureSubmitting = useSelector(getESignatureSubmitting)

  const submittingState = getLoadingState(scheduleApptLoading)

  const payingForAppointment = useSelector(getPayForAppointmentSubmitting)

  const payingForAppointmentState = getLoadingState(payingForAppointment)

  useEffect(() => {
    dispatch(appointmentActions.scheduleAppointment.request())
  }, [dispatch])

  const handleDraftCreationSuccess = useCallback(() => {
    if (isLabOrderingFlow) {
      dispatch(appointmentActions.payForAppointment.request())
    } else {
      history.replace(
        savedPaymentId &&
          selectedPaymentMethod === IndicatedPaymentMethod.insurance
          ? RoutePath.paymentSuccessful
          : RoutePath.choosePaymentCard
      )
    }
  }, [history, savedPaymentId, selectedPaymentMethod, isLabOrderingFlow])

  useLoadingSuccess(
    eSignatureSubmitting,
    isLabOrderingFlow
      ? () => history.push(RoutePath.paymentSuccessful)
      : handleDraftCreationSuccess
  )

  useLoadingFailureAlert(payingForAppointment)

  useEffect(() => {
    if (scheduledAppointment?.id && storedSignature) {
      dispatch(
        authActions.uploadESignature.request({
          signature: storedSignature,
          appointmentId: scheduledAppointment.id,
        })
      )
    } else if (
      scheduledAppointment &&
      !scheduledAppointment.id &&
      storedSignature
    ) {
      handleDraftCreationSuccess()
    }
  }, [scheduledAppointment?.id])

  useLoadingFailure(scheduleApptLoading, (loading) => {
    const errorCode = extractErrorCode(loading.error)

    if (
      errorCode !== ErrorCodes.ApptTimeIsNotAvialable &&
      errorCode !== ErrorCodes.ApptAlreadyExists
    ) {
      return
    }

    history.replace({
      pathname: RoutePath.appointmentTime,
      state: {
        header:
          errorCode === ErrorCodes.ApptTimeIsNotAvialable
            ? `Unfortunately, the time is already booked. Please choose another time ${
                isTelemedicineClinic || isLabOrderingFlow
                  ? 'for online consultation'
                  : 'at clinic location'
              }.`
            : extractErrorMessage(loading.error),
        hideGoBack: true,
        nextPage: RoutePath.createAppointment,
      },
    })
  })

  const title = useMemo(() => {
    if (submittingState.pending || payingForAppointmentState.pending) {
      return 'Please wait while we create your appointment...'
    }

    if (submittingState.failure || payingForAppointmentState.failure) {
      return extractErrorMessage(
        scheduleApptLoading.error || payingForAppointment.error
      )
    }

    return ''
  }, [submittingState, scheduleApptLoading, payingForAppointmentState])

  return (
    <AuthLayout title={title}>
      {!submittingState.failure && !payingForAppointmentState.failure && (
        <Box
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      )}
    </AuthLayout>
  )
}

export default CreateApptLoading
