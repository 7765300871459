import React, { useCallback, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import ThemedButton from 'src/components/common/ThemedButton'
import AuthLayout from '../../components/layouts/AuthLayout'
import Signature, { SignatureRef } from '../../components/common/Signature'
import FooterButtons from '../../components/FooterButtons'
import authActions from '../../store/auth/actions'
import Paper from '../../components/common/Paper'
import { RoutePath } from '../../routes'
import theme from '../../utils/theme'
import { getUserProfile } from '../../store/auth/selectors'
import { useIsLabOrderingFlow } from '../../hooks/labOrdering/useIsLabOrderingFlow'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(1, 2),
  },
  tosTitle: {
    fontWeight: 600,
    color: theme.palette.primary.dark,
  },
  tosBody: {
    color: theme.palette.primary.dark,
  },
  button: {
    borderColor: theme.palette.primary.light,
    justifyContent: 'space-between',
    color: theme.palette.primary.dark,
    borderWidth: '2px',
    '&:hover': {
      borderWidth: '2px',
    },
  },
}))

const TosesAndSignature: React.FC = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const signature = useRef<SignatureRef>(null)

  const history = useHistory()

  const [hasSignature, setHasSignature] = useState(false)

  const isLabOrderingFlow = useIsLabOrderingFlow()

  const profile = useSelector(getUserProfile)

  const handleGoNextForLabOrder = useCallback(() => {
    if (profile?.attachmentIdCardId) {
      history.push(RoutePath.appointmentReasons)
    } else {
      history.push(RoutePath.idCard)
    }
  }, [profile?.attachmentIdCardId, history])

  const handleGoNextGorAppointment = useCallback(() => {
    if (profile?.attachmentIdCardId) {
      history.replace(RoutePath.choosePaymentMethod)
    } else {
      history.replace(RoutePath.idCard)
    }
  }, [profile?.attachmentIdCardId, history])

  const handleGoNext = useCallback(() => {
    if (isLabOrderingFlow) {
      handleGoNextForLabOrder()
    } else {
      handleGoNextGorAppointment()
    }
  }, [isLabOrderingFlow, handleGoNextGorAppointment, handleGoNextForLabOrder])

  const handleChange = useCallback((touched) => {
    setHasSignature(touched)
  }, [])

  const handleSubmit = useCallback(async () => {
    if (!hasSignature) {
      return
    }

    const blob = await signature.current?.getBlob({
      mime: 'image/png',
      quality: 0.8,
    })

    if (!blob) {
      return
    }

    const file = new File([blob], 'signatue.png')

    dispatch(authActions.storeESignature({ signature: file }))

    handleGoNext()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, hasSignature, handleGoNext])

  return (
    <AuthLayout
      title="Action Urgent Care Policies & Consents"
      bottomActions={
        <FooterButtons
          nextOnly
          nextButtonLabel="Next"
          onNextButtonClick={handleSubmit}
          disableNext={!hasSignature}
        />
      }
    >
      <Box gap={2} marginBottom={3} display="flex" flexDirection="column">
        <Paper className={classes.paper}>
          <Typography color="primary" gutterBottom className={classes.tosTitle}>
            Scheduling Policy
          </Typography>
          <Typography variant="body2" className={classes.tosBody}>
            A 50$ fee if cancel or reschedule in less than 24h
          </Typography>
        </Paper>
        <ThemedButton
          fullWidth
          className={classes.button}
          color="primary"
          variant="outlined"
          endIcon={
            <ArrowForwardIosIcon htmlColor={theme.palette.primary.light} />
          }
          onClick={() =>
            window.open('https://kyla.com/p/auc-ahc-consent-form/', '_blank')
          }
        >
          Consent and Authorization
        </ThemedButton>
        <ThemedButton
          fullWidth
          className={classes.button}
          color="primary"
          variant="outlined"
          endIcon={
            <ArrowForwardIosIcon htmlColor={theme.palette.primary.light} />
          }
          onClick={() =>
            window.open(
              'https://kyla.com/p/kyla-notice-of-privacy-practices/',
              '_blank'
            )
          }
        >
          Notice of Privacy Practices
        </ThemedButton>
        <Typography variant="body2" className={classes.tosBody}>
          By signing, you have <b>read and agree</b> to the Scheduling Policy,
          Consent and Authorization, and Notice of Privacy Practices above.
        </Typography>
      </Box>

      <Signature ref={signature} onChange={handleChange} />
    </AuthLayout>
  )
}

export default TosesAndSignature
