import { ActionType, createReducer } from 'typesafe-actions'
import moment, { Moment } from 'moment'

import authActions from 'src/store/auth/actions'
import { insuranceActions } from '../../insurance/actions'
import {
  DuplicationData,
  UsersProfile,
  VerificationOptionsTypes,
} from '../models'
import { NewAccountFormValues } from '../../../components/forms/NewAccountForm'

export const verificationSentTimeLabels: Record<
  VerificationOptionsTypes,
  string
> = {
  [VerificationOptionsTypes.email]: 'emailVerificationSentTime',
  [VerificationOptionsTypes.phone]: 'phoneVerificationSentTime',
}

interface State {
  generalInfo: NewAccountFormValues
  profile: { id: number; token: string; info: UsersProfile }
  temporaryUserId: number | null
  accountDeleted: boolean
  accountBlacklisted: boolean
  reactivateAccountVerificationCode: null | string

  emailVerificationSentTime: Moment | null
  phoneVerificationSentTime: Moment | null
  reactivationCodeSentTime: Moment | null
  verifyEmailCodeSentTime: Moment | null
  verificationType: VerificationOptionsTypes | ''
  updatedPassword: string | null
  duplicationInfo: DuplicationData
  signature: File | null
  temporaryPatientAddressDetails: {
    address: string
    zipCode: string
    state: string
    city: string
  }
  analyticId: string | null
  outdatedTosList: string[]
}

const initialState: State = {
  generalInfo: {} as NewAccountFormValues,
  profile: {} as any,
  temporaryUserId: null,
  accountDeleted: false,
  accountBlacklisted: false,
  reactivateAccountVerificationCode: null,

  emailVerificationSentTime: null,
  phoneVerificationSentTime: null,
  reactivationCodeSentTime: null,
  verifyEmailCodeSentTime: null,
  verificationType: '',
  updatedPassword: null,
  temporaryPatientAddressDetails: {
    address: '',
    zipCode: '',
    state: '',
    city: '',
  },
  analyticId: null,
  duplicationInfo: {} as DuplicationData,
  signature: null,
  outdatedTosList: [],
}

export default createReducer<
  State,
  ActionType<typeof authActions | typeof insuranceActions>
>(initialState)
  .handleAction(
    authActions.signIn.request,
    (state, { payload: { email } }): State => ({
      ...state,
      generalInfo: { ...state.generalInfo, email },
    })
  )
  .handleAction(
    authActions.updateGeneralInfo,
    (state, { payload: { info } }): State => ({ ...state, generalInfo: info })
  )
  .handleAction(
    authActions.checkKylaDuplicate.success,
    (state, { payload: { temporaryUserId } }): State => ({
      ...state,
      temporaryUserId,
    })
  )
  .handleAction(
    authActions.signIn.success,
    (state, { payload: { id, token, profile, analyticId } }): State => ({
      ...state,
      profile: { id, token, info: profile },
      analyticId,
    })
  )
  .handleAction(
    [authActions.createAccount.success, authActions.updateTos.success],
    (state, { payload: { id, token, profile } }): State => ({
      ...state,
      profile: { id, token, info: profile },
    })
  )
  .handleAction(
    authActions.logOut,
    (state): State => ({
      ...state,
      profile: {} as any,
    })
  )
  .handleAction(
    authActions.storeAccountDeletedState,
    (
      state,
      { payload: { accountDeleted, accountBlacklisted, analyticId } }
    ): State => ({
      ...state,
      accountDeleted,
      accountBlacklisted,
      analyticId,
    })
  )
  .handleAction(
    authActions.clearAccountDeletedState,
    (state): State => ({
      ...state,
      accountDeleted: false,
    })
  )
  .handleAction(
    authActions.checkEmail.request,
    (state, { payload: { email } }): State => ({
      ...state,
      generalInfo: { ...state.generalInfo, email },
    })
  )
  .handleAction(
    authActions.checkEmail.success,
    (state, { payload: { analyticId } }): State => ({
      ...state,
      analyticId,
    })
  )
  .handleAction(
    authActions.reactivateAccount.success,
    (state, { payload: { verificationCode } }): State => ({
      ...state,
      reactivateAccountVerificationCode: verificationCode,
    })
  )
  .handleAction(
    authActions.getVerificationCode.success,
    (state, { payload: { verificationType } }): State => {
      const relatedStoreKey = verificationSentTimeLabels[verificationType]
      return {
        ...state,
        [relatedStoreKey]: moment(),
        verificationType,
      }
    }
  )
  .handleAction(
    authActions.sendVerifyEmailCode.success,
    (state): State => ({
      ...state,
      verifyEmailCodeSentTime: moment(),
    })
  )

  .handleAction(
    authActions.sendReactivationCode.success,
    (state): State => ({
      ...state,
      reactivationCodeSentTime: moment(),
    })
  )
  .handleAction(
    [
      insuranceActions.verifyInsurance.success,
      authActions.getProfile.success,
      authActions.updateProfile.success,
    ],
    (state, { payload: { profile } }): State => ({
      ...state,
      profile: { ...state.profile, info: profile },
    })
  )
  .handleAction(
    authActions.updatePassword.request,
    (state, { payload: { password } }): State => ({
      ...state,
      updatedPassword: password,
    })
  )
  .handleAction(
    authActions.updateProfile.request,
    (state, { payload }): State => ({
      ...state,
      temporaryPatientAddressDetails: { ...payload } as any,
    })
  )
  .handleAction(
    authActions.clearPatientAddressDetails,
    (state): State => ({
      ...state,
      temporaryPatientAddressDetails: {
        address: '',
        zipCode: '',
        state: '',
        city: '',
      },
    })
  )
  .handleAction(
    authActions.storeDuplicationInfo,
    (state, { payload: { duplicationInfo } }): State => ({
      ...state,
      duplicationInfo,
    })
  )
  .handleAction(
    authActions.storeESignature,
    (state, { payload: { signature } }): State => ({
      ...state,
      signature,
    })
  )
  .handleAction(
    authActions.clearDuplicationInfo,
    (state): State => ({
      ...state,
      duplicationInfo: {} as DuplicationData,
    })
  )
  .handleAction(authActions.clearData, (): State => initialState)

  .handleAction(
    authActions.storeOutdatedTos,
    (state, { payload: { outdatedTosList } }): State => ({
      ...state,
      outdatedTosList,
    })
  )
  .handleAction(
    authActions.clearOutdatedTos,
    (state): State => ({
      ...state,
      outdatedTosList: [],
    })
  )
