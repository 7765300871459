import {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import SignatureCanvas from 'react-signature-canvas'
import makeStyles from '@mui/styles/makeStyles'
import { Box, Button, Typography } from '@mui/material'
import usePrevious from '../../hooks/usePrevious'
import Paper from './Paper'

export type SignatureProps = {
  onChange(touched: boolean): void
}

export type SignatureRef = {
  getBlob(options: { mime: string; quality: number }): Promise<Blob | null>
}

const useStyles = makeStyles((theme) => ({
  canvas: {
    marginBottom: theme.spacing(2),
    overflow: 'hidden',
    position: 'relative',
  },
  hint: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    userSelect: 'none',
    color: theme.palette.grey[500],
    pointerEvents: 'none',
    '& span': {
      display: 'block',
      whiteSpace: 'nowrap',
    },
  },
}))

const Signature = forwardRef<SignatureRef, SignatureProps>(
  ({ onChange }, ref) => {
    const classes = useStyles()
    const [touched, setTouched] = useState(false)
    const prevToched = usePrevious(touched)

    const canvas = useRef<SignatureCanvas | null>(null)

    const handleBegin = useCallback(() => {
      if (touched) {
        return
      }

      setTouched(true)
    }, [touched])

    const handleClearClick = useCallback(() => {
      canvas.current?.clear()
      setTouched(false)
    }, [])

    useEffect(() => {
      if (prevToched === undefined) {
        return
      }

      onChange(touched)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [touched])

    useImperativeHandle(ref, () => ({
      getBlob: (options) =>
        new Promise((resolve) => {
          canvas.current?.getCanvas().toBlob(
            (blob) => {
              resolve(blob)
            },
            options.mime,
            options.quality
          )
        }),
    }))

    return (
      <Box>
        <Paper className={classes.canvas}>
          <SignatureCanvas
            ref={canvas}
            penColor="#000"
            minWidth={2}
            maxWidth={2}
            dotSize={2}
            minDistance={0}
            onBegin={handleBegin}
            canvasProps={{ width: 337, height: 200 }}
          />
          {!touched && (
            <Typography align="center" className={classes.hint}>
              <span>Please Sign Here</span>
              <span>Using TouchPad or Mouse</span>
            </Typography>
          )}
        </Paper>
        <Button
          color="primary"
          variant="text"
          fullWidth
          disabled={!touched}
          onClick={handleClearClick}
        >
          Clear
        </Button>
      </Box>
    )
  }
)

export default memo(Signature)
